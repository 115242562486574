<template>
    <div class="monitoring-dashboard">
        <b-button variant="success" @click="modalTest = !modalTest">Открыть модальное окно</b-button>
        <b-modal
            v-model="modalTest"
            modal-class="modal-table staffing-table-modal fixed-bottom-row"
            size="xl"
            centered
            title=" "
            hide-footer
        >
            <!-- Поле ввода текста для поиска, кнопка очистки текста -->
            <div class="modal-search">
                <b-form-group>
                    <!-- Поле ввода текста для поиска -->
                    <b-form-input ref="searchTextField" placeholder="Поиск" type="text"/>
                    <!-- Кнопка очистки текста -->
                    <i class="icon icon-close clear-button"/>
                </b-form-group>
            </div>

            <!-- Таблица -->
            <div class="table-container modal-table-card">
                <!-- Таблица -->
                <b-table-simple
                    responsive="true"
                    bordered
                    striped
                    hover
                    head-variant="light"
                    sticky-header="true"
                    no-border-collapse
                >
                    <b-thead>
                        <b-tr>
                            <b-td class="toggle-show">
                                <button type="button" class="btn btn-secondary">
                                    <i class="icon icon-chevron-circle icon-rotate-180" v-if="isOpenAll"></i>
                                    <i class="icon icon-chevron-circle" v-else></i>
                                </button>
                            </b-td>
                            <b-td colspan="2">ГУ / Форма БИП</b-td>
                           <b-td class="nowrap">Сумма тыс. тенге</b-td>
                        </b-tr>
                    </b-thead>

                    <b-tbody>
                      <template v-for="(item, index) in modalTableItems">
                          <b-tr class="table-info" :key="index">
                              <b-td class="toggle-show">
                                  <button type="button" class="btn btn-secondary" @click="openSecond(index)">
                                      <i class="icon icon-chevron-circle icon-rotate-180" v-if="item.isOpen"></i>
                                      <i class="icon icon-chevron-circle" v-else></i>
                                  </button>
                              </b-td>
                              <b-td colspan="2">{{item.name}}</b-td>
                              <b-td class="text-right"><b>{{item.summ}}</b></b-td>
                          </b-tr>
                          <template v-if="item.isOpen">
                              <b-tr :key="index">
                                  <b-td colspan="2"></b-td>
                                  <b-td><b>01-151</b></b-td>
                                  <b-td class="text-right"><span class="text-lightblue text-underline">1245</span></b-td>
                              </b-tr>
                              <b-tr :key="index">
                                  <b-td colspan="2"></b-td>
                                  <b-td><b>01-151</b></b-td>
                                  <b-td class="text-right"><span class="text-lightblue text-underline">1245</span></b-td>
                              </b-tr>
                          </template>
                      </template>
                        <b-tr>
                            <b-td colspan="3" class="text-right text-uppercase"><b>Итого</b></b-td>
                            <b-td class="text-right"><b>241242</b></b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>

        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'examples',
        data() {
            return {
                isOpenAll: false,
                modalTest: false,
                modalFields: [
                    {
                        key: 'name',
                        label: 'ГУ / Форма БИП',
                        variant: 'primary'
                    },
                    {
                        key: 'summ',
                        label: 'Сумма тыс. тенге',
                        variant: 'primary'
                    }
                ],
                modalTableItems: [
                    {
                        name: '7003002 - КГУ “Павлодарское учреждение по охране лесов и животного мира” государственного учреждения “Управление недропользования, окружающей среды и водных ресурсов Павлодарской области”',
                        summ: '10 342',
                        isOpen: false
                    },
                    {
                        name: '7003003 - Коммунальное государственное учреждение “Урлютюбское учреждение по охране лесов и\n' +
                            'животного мира” государственного учреждения “Управление недропользования, окружающей среды\n' +
                            'и водных ресурсов Павлодарской области”',
                        summ: '10 342',
                        isOpen: false
                    },
                    {
                        name: '7003002 - КГУ “Павлодарское учреждение по охране лесов и животного мира” государственного учреждения\n' +
                            '“Управление недропользования, окружающей среды и водных ресурсов Павлодарской области”',
                        summ: '10 342',
                        isOpen: false
                    },
                    {
                        name: '7003002 - КГУ “Павлодарское учреждение по охране лесов и животного мира” государственного учреждения\n' +
                            '“Управление недропользования, окружающей среды и водных ресурсов Павлодарской области”',
                        summ: '10 342',
                        isOpen: false
                    },
                    {
                        name: '7003002 - КГУ “Павлодарское учреждение по охране лесов и животного мира” государственного учреждения\n' +
                            '“Управление недропользования, окружающей среды и водных ресурсов Павлодарской области”',
                        summ: '10 342',
                        isOpen: false
                    }, {
                        name: '7003002 - КГУ “Павлодарское учреждение по охране лесов и животного мира” государственного учреждения\n' +
                            '“Управление недропользования, окружающей среды и водных ресурсов Павлодарской области”',
                        summ: '10 342',
                        isOpen: false
                    }, {
                        name: '7003002 - КГУ “Павлодарское учреждение по охране лесов и животного мира” государственного учреждения\n' +
                            '“Управление недропользования, окружающей среды и водных ресурсов Павлодарской области”',
                        summ: '10 342',
                        isOpen: false
                    },
                    {
                        name: '7003002 - КГУ “Павлодарское учреждение по охране лесов и животного мира” государственного учреждения\n' +
                            '“Управление недропользования, окружающей среды и водных ресурсов Павлодарской области”',
                        summ: '10 342',
                        isOpen: false
                    },
                    {
                        name: '7003002 - КГУ “Павлодарское учреждение по охране лесов и животного мира” государственного учреждения\n' +
                            '“Управление недропользования, окружающей среды и водных ресурсов Павлодарской области”',
                        summ: '10 342',
                        isOpen: false
                    },
                    {
                        name: '7003002 - КГУ “Павлодарское учреждение по охране лесов и животного мира” государственного учреждения\n' +
                            '“Управление недропользования, окружающей среды и водных ресурсов Павлодарской области”',
                        summ: '10 342',
                        isOpen: false
                    },


                ]
            }
        },
        methods: {
            openSecond(i){
                this.modalTableItems[i].isOpen = !this.modalTableItems[i].isOpen;
            }
        },
        computed: {}
    }
</script>